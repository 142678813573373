/* eslint-disable react/prop-types */
import React from 'react'
import { Link } from 'gatsby'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import NavPills from 'components/NavPills/NavPills.js'
import Box from '@material-ui/core/Box'

import styles from 'assets/jss/material-kit-react/views/sections/findStyle.js'

const useStyles = makeStyles(styles)

const FindSection = props => {
  const classes = useStyles()

  return (
    <div className={classes.section}>
      <h4 className={classes.title}>BROWSE BY CATEGORY</h4>
      <GridContainer alignItems="center" justifyContent="center">
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <NavPills
            color="primary"
            tabs={
              props.data &&
              props.data.allContentfulBrandCategory &&
              props.data.allContentfulBrandCategory.edges
                .sort((a, b) => {
                  if (a.node.order < b.node.order) {
                    return -1
                  }
                  if (a.node.order > b.node.order) {
                    return 1
                  }
                  return 0
                })
                .map(brandCategory => ({
                  tabButton: brandCategory.node.title.title,
                  tabContent: (
                    <div>
                      <br />
                      <GridContainer alignItems="center" justifyContent="center">
                        {brandCategory &&
                          brandCategory.node &&
                          brandCategory.node.brands &&
                          brandCategory.node.brands
                            .sort((a, b) => {
                              if (a.companyName < b.companyName) {
                                return -1
                              }
                              if (a.companyName > b.companyName) {
                                return 1
                              }
                              return 0
                            })
                            .map(brand => (
                              <div
                                key={brand.companyName.id}
                                style={{ paddingLeft: '10px' }}
                              >
                                <Link
                                  to={`/search/brand/${brand.companyName.companyName}`}
                                  state={{
                                    brand: brand,
                                    name: brand.companyName.companyName,
                                    keywords: '',
                                  }}
                                >
                                  <Box
                                    boxShadow={3}
                                    bgcolor="background.paper"
                                    m={1}
                                    p={2}
                                    style={{ width: '7rem', height: '5rem' }}
                                  >
                                    <img
                                      title={brand.companyName.companyName}
                                      src={
                                        brand.logo ? brand.logo.file.url : ''
                                      }
                                    />
                                  </Box>
                                </Link>
                              </div>
                            ))}
                      </GridContainer>
                    </div>
                  ),
                }))
            }
          />
        </GridItem>
      </GridContainer>
    </div>
  )
}

export default FindSection
